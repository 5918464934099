import React from "react";
import Richtext from "./Richtext";
import getImageSrc from "../util/getImageSrc";

function Testimonial({ backgroundImage, children }) {
  const bg = getImageSrc(backgroundImage);
  return (
    <div className="testimonial" style={{ backgroundImage: `url(${bg})` }}>
      <Richtext>{children}</Richtext>
      <span className="tint tint1" />
      <span className="tint tint2" />
    </div>
  );
}

export default Testimonial;
