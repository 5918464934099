import React from "react";
import { Link } from "gatsby";
import Richtext from "./Richtext";
import Container from "./Container";
import FormModal from "./FormModal";
import getImageSrc from "../util/getImageSrc";

function Slide({
  title,
  description,
  image,
  buttonTo,
  externalLink,
  light = false,
  buttonLabel = "Learn More",
  form,
  formHeight = 500,
  formLabel,
  target="_self"
}) {
  const bg = getImageSrc(image);
  const btn_clss = typeof form === "undefined" ? 'button solid' : 'button outline';
  const btn_form_clss = 'button solid';
  const btn_form_label = formLabel ? formLabel : buttonLabel
  return (
    <div
      className={`slide ${light ? "text-light" : ""}`}
      style={{ backgroundImage: `url(${bg})` }}
    >
      <div className="txt">
        <Container>
          <Richtext>
            <h2>{title}</h2>
            <p>{description}</p>

            {typeof form !== "undefined" && (
              <FormModal src={form} buttonType={btn_form_clss} buttonLabel={btn_form_label} height={formHeight} />
            )}
            {typeof buttonTo !== "undefined" && (
              <Link className={btn_clss} to={buttonTo} target={target}>
                {buttonLabel}
              </Link>
            )}
            {typeof externalLink !== "undefined" && (
              <a className={btn_clss} href={externalLink} target={target} rel="noreferrer noopener">{buttonLabel}</a>
            )}
          </Richtext>
        </Container>
      </div>
    </div>
  );
}

export default Slide;
