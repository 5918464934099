import React from "react";
import { GatsbyImage, getSrc } from "gatsby-plugin-image";
import error_img from "../images/error.png";
import getSalAttrs from "../util/getSalAttrs";
import { Link } from "gatsby";

function InlineImage({
  data,
  alt = "Picture",
  align = "center",
  className = "",
  width,
  fluid = false,
  caption,
  sal,
  to,
  maxWidth = '100%',
  target = '_self'
}) {
  let src = error_img;
  const w =
    width && `${width}`.includes("%") ? width : width ? `${parseInt(width)}px` : "100%";

  if (data && data.gatsbyImageData) {
    src = getSrc(data.gatsbyImageData);
  } else {
    alt = "Image not found";
    className += " not-found";
  }
  className += ` align-${align}`;
  const img =
    fluid && src ? (
      <GatsbyImage image={data.gatsbyImageData} alt={alt} />
    ) : (
      <img src={src} alt={alt} />
    );

  if (caption) {
    return (
      <figure
        className={`inline-image ${className}`}
        {...getSalAttrs(sal)}
        style={{ width: w, maxWidth }}
      >
        {img}
        <figcaption>
          <i className="icon-up" />
          <span>{caption}</span>
        </figcaption>
      </figure>
    );
  }

  if (to) {
    return (
      <Link
        className={`inline-image ${className}`}
        {...getSalAttrs(sal)}
        style={{ width: w, maxWidth }}
        to={to}
        target={target}
      >
        {img}
      </Link>
    );
  }

  return (
    <span
      className={`inline-image ${className}`}
      {...getSalAttrs(sal)}
      style={{ width: w, maxWidth }}
    >
      {img}
    </span>
  );
}

export default InlineImage;
