import React from "react";
import { Link } from "gatsby";

function SubpagesNav({ data }) {
  const pathname = typeof window !== "undefined" && window.location?.pathname;
  return (
    <nav className="anchor-nav">
      <ul>
        {data.map(({ slug, label }) => (
          <li key={slug} className={`${pathname.includes(slug) ? "active" : ""}`}>
            <Link to={slug}>{label}</Link>
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default SubpagesNav;
