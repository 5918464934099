import React from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";

function Map({ lat, lng }) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.GATSBY_GOOGLE_MAPS_KEY,
  });
  const center = {
    lat: parseFloat(lat),
    lng: parseFloat(lng),
  };

  if (!isLoaded) return <></>;
  return (
    <div id="map">
      <div>
        <GoogleMap
          mapContainerStyle={{
            width: "100%",
            height: "100%",
          }}
          center={center}
          zoom={15}
          clickableIcons={false}
        >
          <>
            <Marker position={center} />
          </>
        </GoogleMap>
      </div>
    </div>
  );
}

export default Map;
