import React from "react";
import { Link } from "gatsby";
import Section from "./Section";
import Richtext from "./Richtext";
import Container from "./Container";
import FormModal from "./FormModal";

function CallToAction({
  title,
  description,
  buttonTo,
  bgcolor = "white",
  buttonLabel = "Learn More",
  form,
  formHeight = 500,
  formLabel,
  target="_self"
}) {

  const btn_clss = typeof form === "undefined" ? 'button solid' : 'button outline';
  const btn_form_clss = 'button solid';
  const btn_form_label = formLabel ? formLabel : buttonLabel

  return (
    <Section isCallToAction={true} title={title} bgcolor={bgcolor}>
      <Container size="sm">
        <Richtext align="center">
          {description !== "undefined" && <p>{description}</p>}
          {typeof form !== "undefined" && (
            <FormModal src={form} buttonType={btn_form_clss} buttonLabel={btn_form_label} height={formHeight} />
          )}
          {typeof buttonTo !== "undefined" && (
            <Link className={btn_clss} to={buttonTo}  target={target}>
              {buttonLabel}
            </Link>
          )}
        </Richtext>
      </Container>
    </Section>
  );
}

export default CallToAction;
