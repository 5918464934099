import React from "react";
import Column from "./Column";
import Modal from "./Modal";
import default_thumbnail from "../images/video-thumbnail-default.jpg";
import getImageSrc from "../util/getImageSrc";

function Video({ title, source, id, featured, thumbnail }) {
  const [modalShow, setModalShow] = React.useState(false);
  const img = getImageSrc(thumbnail, default_thumbnail);
  let video_url = "";

// eslint-disable-next-line default-case
  switch (source) {
    case "youtube":
      video_url = `https://www.youtube.com/watch?v=${id}`;
      break;
    case "vimeo":
      video_url = `https://vimeo.com/${id}`;
      break;
  }

  React.useEffect(() => {
    if (modalShow) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalShow]);

  return (
    <Column size={featured ? 9 : 4}>
      <div className="video">
        <a
          onClick={(e) => {
            e.preventDefault();
            setModalShow(true);
          }}
          href={video_url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div>
            <i className="icon-play"></i>

            <span className="img" style={{ backgroundImage: `url(${img})` }}></span>
          </div>
          {title && <strong>{title}</strong>}
        </a>
        {modalShow && (
          <Modal setModalShow={setModalShow}>
            <div className="video-embed">
              {source === "youtube" && (
                <iframe
                  width="560"
                  height="315"
                  src={`https://www.youtube.com/embed/${id}`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  autoplay
                  title={title}
                ></iframe>
              )}
              {source === "vimeo" && (
                <iframe
                  src={`https://player.vimeo.com/video/${id}`}
                  width="640"
                  height="360"
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen
                  title={title}
                ></iframe>
              )}
            </div>
          </Modal>
        )}
      </div>
    </Column>
  );
}

export default Video;
