import React from "react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";
import { graphql, Link } from "gatsby";
import inlineImagesReducer from "../util/inlineImagesReducer";

import Section from "../components/Section";
import Container from "../components/Container";
import Row from "../components/Row";
import Column from "../components/Column";
import Richtext from "../components/Richtext";
import InlineImage from "../components/InlineImage";
import Blurb from "../components/Blurb";
import Spacer from "../components/Spacer";
import Carousel from "../components/Carousel";
import Slide from "../components/Slide";
import Testimonial from "../components/Testimonial";
import CallToAction from "../components/CallToAction";
import Banner from "../components/Banner";
import AnchorNav from "../components/AnchorNav";
import SubpagesNav from "../components/SubpagesNav";
import Accordion from "../components/Accordion";
import Tab from "../components/Tab";
import Map from "../components/Map";
import Member from "../components/Member";
import Logos from "../components/Logos";
import Staff from "../components/Staff";
import Video from "../components/Video";
import Resource from "../components/Resource";
import FormModal from "../components/FormModal";
import FAQs from "../layouts/FAQs";
import Videos from "../layouts/Videos";


export default function Page({ data, pageContext }) {
  const inlineImages = inlineImagesReducer(data.mdx.frontmatter.inlineImages);
  const components = {
    Container,
    Section,
    Row,
    Column,
    Richtext,
    InlineImage,
    Blurb,
    Spacer,
    Carousel,
    Slide,
    Testimonial,
    CallToAction,
    Banner,
    AnchorNav,
    SubpagesNav,
    Accordion,
    Tab,
    Link,
    FAQs,
    Map,
    Staff,
    Member,
    Logos,
    Video,
    Videos,
    Resource,
    FormModal,
  };

  return (
    <MDXProvider components={components}>
      <MDXRenderer
        inlineImages={inlineImages}
        exports={data.mdx.exports}
        pageContext={pageContext}
      >
        {data.mdx.body}
      </MDXRenderer>
    </MDXProvider>
  );
}
export const query = graphql`
  query($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
        meta_title
        meta_description
        excerpt
        meta_image
        slug
        inlineImages {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      body
      exports {
        anchorNavData {
          label
          anchor
        }
      }
    }
  }
`;
