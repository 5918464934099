import React from "react";
import Richtext from "./Richtext";

function Tab({ children }) {
  return (
    <div className="tab">
      <Richtext>{children}</Richtext>
    </div>
  );
}

export default Tab;
