import React from "react";
import { SiteContext } from "../layouts/Main";

function Logos({ children, scrollable = false, cols = 4, bg = "blue" }) {
  const [currentPosition, setCurrentPosition] = React.useState(1);
  const [currentStatus, setCurrentStatus] = React.useState("start");
  const siteContext = React.useContext(SiteContext);
  function positionSet(direction) {
    const total_items = children.length;
    let responsive_cols = cols;
    if (siteContext.currentBreakpoint === "md") responsive_cols = 3;
    if (siteContext.currentBreakpoint === "sm" || siteContext.currentBreakpoint === "xs")
      responsive_cols = 2;
    if (total_items > responsive_cols) {
      let max_pos = Math.abs(responsive_cols - (total_items + 1));
      let next_pos = direction === "prev" ? currentPosition - 1 : currentPosition + 1;
      let curr_status = "none";
      if (next_pos < 1) {
        next_pos = max_pos;
        curr_status = "end";
      }
      if (next_pos > max_pos) {
        next_pos = 1;
        curr_status = "start";
      }
      setCurrentPosition(next_pos);
      setCurrentStatus(curr_status);
    }
  }

  return (
    <div
      className={`logos ${scrollable ? "scrollable" : ""} count-${
        children.length
      } cols-${cols} position-${currentPosition} status-${currentStatus}`}
    >
      <div>
        <div className="ul">{children}</div>
        {scrollable === true && (
          <>
            <span className={`shade shade-left bg-${bg}`}>
              <b />
              <i />
            </span>
            <span className={`shade shade-right bg-${bg}`}>
              <i />
              <b />
            </span>
          </>
        )}
      </div>
      {scrollable === true && (
        <aside>
          <button
            title="Previous"
            className="button white prev"
            onClick={(e) => {
              positionSet("prev");
            }}
          >
            <i className="icon-left" />
          </button>
          <button
            title="Next"
            className="button white next"
            onClick={(e) => {
              positionSet("next");
            }}
          >
            <i className="icon-right" />
          </button>
        </aside>
      )}
    </div>
  );
}

export default Logos;
