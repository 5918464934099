import React from "react";
import genID from "../util/genID";
import slugify from "../util/slugify";
import Container from "./Container";

function calcCols(n) {
  return n < 5 ? 2 : 4;
}

function Staff({ children }) {
  let categories = children
    .filter((child) => typeof child.props.category !== "undefined")
    .map((child) => child.props.category);
  categories = new Set([...categories]);
  categories = ["All", ...categories];
  const [currentCategory, setCurrentCategory] = React.useState(categories[0]);
  const [activeChildren, setActiveChildren] = React.useState(children);
  const [cols, setCols] = React.useState(calcCols(children.length));
  React.useEffect(() => {
    if (currentCategory === "All") {
      setActiveChildren(children);
    } else {
      setActiveChildren(
        children.filter((child) => child.props.category === currentCategory)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCategory]);

  React.useEffect(() => {
    setCols(calcCols(activeChildren.length));
  }, [activeChildren]);

  return (
    <div id="staff">
      <div className="button-group">
        {categories.map((cat_name) => (
          <button
            key={slugify(cat_name)}
            className={`button ${currentCategory === cat_name ? "solid" : "outline"}`}
            onClick={(e) => {
              setCurrentCategory(cat_name);
            }}
          >
            {cat_name}
          </button>
        ))}
      </div>
      <Container>
        <ul className={`cols-${cols}`}>
          {activeChildren.map((child) => (
            <li key={genID()}>{child}</li>
          ))}
        </ul>
      </Container>
    </div>
  );
}

export default Staff;
