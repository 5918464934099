import React from "react";
import Collapse from "@kunukn/react-collapse";

function Accordion({ children, toggleFirst, id }) {
  const [currentIndex, setCurrentIndex] = React.useState(toggleFirst ? 0 : null);
  const [jsOn, setJsOn] = React.useState(false);

  React.useEffect(() => {
    setJsOn(true);
  }, []);

  if (!id) return <></>;

  function onToggle(index, tab_id) {
    setCurrentIndex(currentIndex === index ? null : index);
    // if (currentIndex !== index) {
    //   setTimeout(() => {
    //     document.getElementById(tab_id).scrollIntoView();
    //   }, 300);
    // }
  }
  return (
    <div className="accordion" id={id}>
      {children.map((child, i) => {
        const tab_id = `accordion-${id}-tab-${i}`;
        return (
          <div
            id={tab_id}
            key={tab_id}
            className={(currentIndex === i && toggleFirst) || !jsOn ? "item--active" : ""}
          >
            <button onClick={() => onToggle(i, tab_id)}>
              <i className={`icon-${currentIndex === i ? "minus" : "plus"}`} />
              <h3>{child.props.title}</h3>
            </button>
            <Collapse
              className="collapse"
              isOpen={currentIndex === i || !jsOn}
              render={(collapseState) => child}
            />
          </div>
        );
      })}
    </div>
  );
}

export default Accordion;
