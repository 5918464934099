import React from "react";
import Banner from "../components/Banner";
import Accordion from "../components/Accordion";
import Container from "../components/Container";
import "../util/pardotAnalytics";
import useAnalytics from "../hooks/useAnalytics";

function FAQs({ pageContext, children, bannerImage }) {
  let cat_strings = children
    .filter((child) => typeof child.props.category !== "undefined")
    .map((child) => child.props.category);

  let categories = [];
  for (const cat_string of cat_strings) {
    categories = [...categories, ...cat_string.split("|")];
  }

  categories = new Set([...categories.map(c => c.trim()).sort()]);
  categories = [...categories];
  const [currentCategory, setCurrentCategory] = React.useState("");
  const [currentFAQs, setCurrentFAQs] = React.useState(children);
  const [jsOn, setJsOn] = React.useState(false);
  React.useEffect(() => {
    setJsOn(true);
  }, []);

  React.useEffect(() => {
    if (currentCategory.length === 0) {
      setCurrentFAQs(children);
    } else {
      setCurrentFAQs(
        children.filter((child) => child.props.category.includes(currentCategory))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCategory]);

  useAnalytics();

  return (
    <div id="FAQs">
      <Banner
        title={pageContext.title}
        filterOptions={categories}
        filterSet={setCurrentCategory}
        jsOn={jsOn}
        image={bannerImage}
      />
      <Container>
        <Accordion id="faq-accordion">{currentFAQs}</Accordion>
      </Container>
    </div>
  );
}

export default FAQs;
