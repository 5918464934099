import React from "react";

function AnchorNav({ data }) {
  return (
    <nav className="anchor-nav">
      <ul>
        {data.map(({ anchor, label }) => (
          <li key={anchor}>
            <a href={`#${anchor}`}>{label}</a>
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default AnchorNav;
