import React from "react";
import Banner from "../components/Banner";
import Container from "../components/Container";
import Row from "../components/Row";
import Spacer from "../components/Spacer";
import "../util/pardotAnalytics";
import useAnalytics from "../hooks/useAnalytics"

function Videos({ pageContext, bannerImage, children }) {
  const _children = Array.isArray(children) ? children : [children];
  
  let cat_strings = _children.filter((child) => typeof child.props.category !== "undefined")
    .map((child) => child.props.category);

  let categories = [];
  for (const cat_string of cat_strings) {
    categories = [...categories, ...cat_string.split("|")];
  }

  categories = new Set([...categories]);
  categories = [...categories];
  const is_videos_archive = typeof bannerImage !== "undefined";
  const posts_per_page = 3;
  const [currentCategory, setCurrentCategory] = React.useState("");
  const [currentVideos, setCurrentVideos] = React.useState(_children);
  const [totalVideos, setTotalVideos] = React.useState(_children.length);
  const [totalFeaturedVideos, setTotalFeaturedVideos] = React.useState(0);
  const [sortedVideos, setSortedVideos] = React.useState(_children);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pagedVideos, setPagedVideos] = React.useState(_children);
  const [allVideosLoaded, setAllVideosLoaded] = React.useState(!is_videos_archive);
  const [jsOn, setJsOn] = React.useState(false);

  React.useEffect(() => {
    setJsOn(true);
  }, []);

  React.useEffect(() => {
    if (currentCategory.length === 0) {
      setCurrentVideos(_children);
    } else {
      setCurrentVideos(
        _children.filter((child) => child.props.category.includes(currentCategory))
      );
    }
    setCurrentPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCategory]);

  React.useEffect(() => {
    setTotalVideos(currentVideos.length);
    setTotalFeaturedVideos(currentVideos.filter((vid) => vid.props.featured).length);
    setSortedVideos(currentVideos.slice().sort((a, b) => (a.props.featured ? -1 : 1)));
  }, [currentVideos]);

  React.useEffect(() => {
    if (is_videos_archive) {
      const paged_videos = [...sortedVideos].splice(
        0,
        posts_per_page * currentPage + totalFeaturedVideos
      );
      setPagedVideos(paged_videos);
      setAllVideosLoaded(totalVideos === paged_videos.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedVideos, currentPage]);

  useAnalytics();

  function loadMore() {
    const all_loaded = totalVideos === pagedVideos.length;
    const current_page = all_loaded ? 1 : currentPage + 1;
    setCurrentPage(current_page);
  }

  return (
    <div id="videos">
      {is_videos_archive && (
        <Banner
          title={pageContext.title}
          filterOptions={categories}
          filterSet={setCurrentCategory}
          image={bannerImage}
          jsOn={jsOn}
        />
      )}
      <Spacer />
      {is_videos_archive && (
        <Container size="md">
          <Row gutter="xl">{pagedVideos}</Row>
          {!allVideosLoaded && jsOn === true && (
            <>
              <Spacer />
              <button className="button solid" onClick={loadMore}>
                Load more
              </button>
            </>
          )}
        </Container>
      )}
      {!is_videos_archive && <Row>{sortedVideos}</Row>}
      <Spacer />
    </div>
  );
}

export default Videos;
