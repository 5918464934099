export default function inlineImagesReducer(inlineImages) {
  const IMAGE_KEY = "image";
  return (
    inlineImages &&
    inlineImages.reduce((images, image, index) => {
      images[`${IMAGE_KEY}${index + 1}`] =
        images[`${IMAGE_KEY}${index + 1}`] || image !== null
          ? {
              ...image.childImageSharp,
            }
          : null;
      return images;
    }, {})
  );
}
