import React from "react";
import { Link, navigate } from "gatsby";
import { SiteContext } from "../layouts/Main";
import getImageSrc from "../util/getImageSrc";

function Blurb({
  title,
  subtitle,
  description,
  buttonTo,
  backgroundImage,
  buttonLabel = "Learn More",
  target = "_self"
}) {
  const siteContext = React.useContext(SiteContext);
  const [flipped, setFlipped] = React.useState(false);
  const bg = getImageSrc(backgroundImage);
  return (
    <Link
      className={`blurb ${siteContext.isMobileOS ? "is-mobile" : ""} ${
        flipped ? "flipped" : ""
      } ${bg !== null ? "has-bg" : "no-bg"}`}
      role="button"
      onMouseDown={(e) => {
        if (siteContext.isMobileOS) setFlipped(!flipped);
      }}
      onMouseEnter={(e) => {
        if (!siteContext.isMobileOS) setFlipped(true);
      }}
      onMouseLeave={(e) => {
        if (!siteContext.isMobileOS) setFlipped(false);
      }}
      onClick={(e) => {
        if (siteContext.isMobileOS) e.preventDefault();
      }}
      to={buttonTo}
      target={target}
    >
      <div className="content">
        <div className="front" style={{ backgroundImage: `url(${bg})` }}>
          <div className="txt">
            <h3>{title}</h3>
          </div>
          <span className="tint tint1" />
          <span className="tint tint2" />
        </div>
        <div className="back" style={{ backgroundImage: `url(${bg})` }}>
          <div className="txt">
            <strong>{subtitle}</strong>
            {description && <p>{description}</p>}
            {buttonTo && (
              <span
                role="button"
                tabIndex="-1"
                onClick={(e) => {
                  if (siteContext.isMobileOS) navigate(buttonTo);
                }}
                onKeyDown={(e) => {
                  if (siteContext.isMobileOS) navigate(buttonTo);
                }}
                className="button white"
              >
                {buttonLabel}
              </span>
            )}
          </div>
          <span className="tint tint1" />
          <span className="tint tint2" />
          <span className="tint tint3" />
        </div>
      </div>
    </Link>
  );
}

export default Blurb;
