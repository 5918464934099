const getSalAttrs = (sal) => {
  const sal_props = typeof sal !== "undefined" ? sal.split(" ") : [];
  return sal_props.length === 4
    ? {
        "data-sal": sal_props[0],
        "data-sal-duration": sal_props[1],
        "data-sal-easing": sal_props[2],
        "data-sal-delay": sal_props[3],
      }
    : {};
};
export default getSalAttrs;
