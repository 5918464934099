import { Link } from "gatsby";
import React from "react";
import photo_default from "../images/staff-photo-default.jpg";
import Richtext from "./Richtext";
import getImageSrc from "../util/getImageSrc";

function Wrapper({ to, children, bg }) {
  if (typeof to === "undefined") {
    return (
      <div className="member" style={{ backgroundImage: `url(${bg})` }}>
        {children}
      </div>
    );
  } else {
    if (to.includes("http")) {
      return (
        <a
          href={to}
          target="_blank"
          rel="noreferrer noopener"
          className="member"
          style={{ backgroundImage: `url(${bg})` }}
        >
          {children}
        </a>
      );
    } else {
      return (
        <Link to={to} className="member" style={{ backgroundImage: `url(${bg})` }}>
          {children}
        </Link>
      );
    }
  }
}

function Member({ name, position, photo, to, category }) {
  const bg = getImageSrc(photo, photo_default);
  return (
    <Wrapper to={to} bg={bg}>
      <Richtext>
        <strong>{name}</strong>
        <em>{position}</em>
      </Richtext>
      <span className="tint tint1" />
      <span className="tint tint2" />
      <span className="tint tint3" />
    </Wrapper>
  );
}

export default Member;
